import cn from 'classnames'

import {useEffect, useRef, useState} from 'react'

import styles from './Video.module.scss'

import Icon from '@/components/atoms/Icon'
import Loading from '@/components/molecules/Loading'

type VideoProps = {
  autoplay?: boolean
  video: {
    mediaItemUrl: string
  }
}

export default function Video({video, autoplay}: VideoProps) {
  const [isVideoPlaying, setIsVideoPlaying] = useState(false)
  const [isLoading, setIsLoading] = useState(false)

  const vidRef = useRef<HTMLVideoElement>(null)

  const handlePlayVideo = () => {
    if (!isVideoPlaying) {
      vidRef.current?.play()
    } else {
      vidRef.current?.pause()
    }
    setIsVideoPlaying(!isVideoPlaying)
  }

  useEffect(() => {
    if (autoplay) setIsVideoPlaying(true)
  }, [autoplay])

  return (
    <div
      onClick={() => handlePlayVideo()}
      className={cn(styles.video, isVideoPlaying ? styles.isPlaying : '')}
    >
      {isLoading && (
        <div className={styles.loading}>
          <Loading />
        </div>
      )}
      <video
        ref={vidRef}
        preload="metadata"
        muted={autoplay}
        webkit-playsinline
        playsInline
        autoPlay={autoplay}
        loop={autoplay}
        onLoadStart={() => setIsLoading(true)}
        onLoadedData={() => setIsLoading(false)}
        onEnded={() => {
          setIsVideoPlaying(false)
          if (!autoplay) vidRef?.current?.load()
        }}
      >
        <source src={video.mediaItemUrl + `#t=0.5s`} />
      </video>
      <Icon
        className={styles.icon}
        icon={isVideoPlaying ? 'pauseCircle' : 'playCircle'}
        size={32}
        sizeViewbox={32}
      />
    </div>
  )
}
