import Button from '@/components/atoms/Button'
import Container from '@/components/atoms/Container'
import {ImageType} from '@/components/types/image'
import {LinkType} from '@/components/types/link'
import Image from 'next/image'
import Link from 'next/link'
import styles from './ImageLink.module.scss'

type ImageLinkProps = {
  block: {
    cards: [
      {
        image: ImageType
        link: LinkType
        title: string
        text: string
      }
    ]
  }
}

export default function ImageLink({block}: ImageLinkProps) {
  const {cards} = block
  return (
    <div>
      {cards?.length > 0 &&
        cards.map((card, index) => (
          <div key={index} className={styles.imageLink}>
            <Container removePaddingMobile>
              <div className={styles.card}>
                {card?.link?.url && (
                  <Link href={card?.link.url}>
                    <a className={styles.textWrapper}>
                      <Button
                        attributes={{'data-aos': 'fade-up'}}
                        type="blueDark"
                        className={styles.button}
                        text={card?.link.title}
                        urlExternal={card.link.target}
                      />

                      <div data-aos="fade-up" className={styles.text}>
                        {card?.text}
                      </div>
                    </a>
                  </Link>
                )}
                {card?.image?.mediaItemUrl && (
                  <div className={styles.imageWrapper}>
                    <Image
                      data-aos="fade-in"
                      src={card?.image.mediaItemUrl}
                      alt={card?.image.altText}
                      width={card?.image.mediaDetails.width}
                      height={card?.image.mediaDetails.height}
                      layout="responsive"
                      objectFit="cover"
                    />
                  </div>
                )}
              </div>
            </Container>
          </div>
        ))}
    </div>
  )
}
