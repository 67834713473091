import Container from '@/components/atoms/Container'
import {ImageType} from '@/components/types/image'
import Image from 'next/image'
import styles from './Logos.module.scss'

type LogosProps = {
  block: {
    logos: [
      {
        image: ImageType
      }
    ]
  }
}

export default function Logos({block}: LogosProps) {
  const {logos} = block

  return (
    <Container removePaddingMobile>
      <div className={styles.logos}>
        {logos?.length > 0 &&
          logos?.map(
            (logo, index) =>
              logo.image.mediaItemUrl && (
                <div key={index} className={styles.image}>
                  <div key={index} className={styles.imageWrapper}>
                    <div key={index} className={styles.imageContainer}>
                      <Image
                        data-aos="fade-in"
                        src={logo.image.mediaItemUrl}
                        alt={logo.image.altText}
                        width={logo.image.mediaDetails.width}
                        height={logo.image.mediaDetails.height}
                        layout="responsive"
                        objectFit="contain"
                      />
                    </div>
                  </div>
                </div>
              )
          )}
      </div>
    </Container>
  )
}
