import Animation from '@/components/atoms/Animation'
import Button from '@/components/atoms/Button'
import Container from '@/components/atoms/Container'
import Map from '@/components/molecules/Map'

import Label from '@/components/atoms/Label'
import {LinkType} from '@/components/types/link'
import styles from './LocationsLinks.module.scss'
type LocationsLinksProps = {
  block: {
    title: string
    animation: string
    locations: [
      {
        title: string
        subtitle: string
        link: LinkType
        address: string
        latitude: number
        longitude: number
        anchor: string
      }
    ]
  }
}

export default function Locations({block}: LocationsLinksProps) {
  const {title, locations, animation} = block
  return (
    <>
      <Label title={title} />
      <div className={styles.locationsLinks}>
        <Container>
          <div className={styles.locationsWrapper}>
            {locations?.length > 0 &&
              locations.map((location, index) => (
                <div
                  id={location.anchor}
                  key={index}
                  className={styles.location}
                >
                  {location.latitude && location.longitude && (
                    <Map
                      latitude={location.latitude}
                      longitude={location.longitude}
                    />
                  )}
                  <div className={styles.textWrapper}>
                    <div data-aos="fade-up" className={styles.title}>
                      {location?.title}
                    </div>
                    <div data-aos="fade-up" className={styles.subtitle}>
                      {location?.subtitle}
                    </div>
                    <div
                      data-aos="fade-up"
                      className={styles.address}
                      dangerouslySetInnerHTML={{__html: location?.address}}
                    />
                    {location?.link?.url && (
                      <Button
                        attributes={{'data-aos': 'fade-up'}}
                        type="blue"
                        className={styles.button}
                        text={location.link.title}
                        icon="arrowOutWard"
                        iconSize={42}
                        url={location?.link.url}
                        urlExternal={location.link.target}
                      />
                    )}
                  </div>
                </div>
              ))}
            {locations?.length % 2 !== 0 && (
              <div className={styles.animationWrapper}>
                <Animation animation={animation} />
              </div>
            )}
          </div>
        </Container>
      </div>
    </>
  )
}
