import {googleMapsApiKey} from '@/lib/wordpress/connector'
import {GoogleMap, Marker, useJsApiLoader} from '@react-google-maps/api'
import {useCallback, useEffect, useState} from 'react'
import {mapStyles} from './MapStyles'

export default function Map({latitude, longitude}) {
  const center = {
    lat: Number(latitude),
    lng: Number(longitude)
  }

  const {isLoaded} = useJsApiLoader({
    id: 'google-map-script',
    googleMapsApiKey: googleMapsApiKey
  })

  const [, setMap] = useState(null)
  const [zoom, setZoom] = useState(5)
  const [googleSettings, setGoogleSettings] = useState(null)

  useEffect(() => {
    if (typeof window !== 'undefined' && window.google) {
      setGoogleSettings(window.google.maps)
    }
  }, [isLoaded])

  const onLoad = useCallback(
    function callback(map) {
      const bounds = new window.google.maps.LatLngBounds(center)
      map.fitBounds(bounds)
      setMap(map)
      setTimeout(() => {
        setZoom(16)
      }, 300)
    },
    [center]
  )

  const onUnmount = useCallback(function callback() {
    setMap(null)
  }, [])

  return isLoaded ? (
    <GoogleMap
      mapContainerStyle={{
        height: '400px',
        margin: '0 auto'
      }}
      center={center}
      onLoad={onLoad}
      onUnmount={onUnmount}
      zoom={zoom}
      options={{
        zoomControlOptions: googleSettings
          ? {
              position: googleSettings.ControlPosition.TOP_RIGHT,
              style: googleSettings.ZoomControlStyle.SMALL
            }
          : undefined,
        mapTypeControlOptions: googleSettings
          ? {
              position: googleSettings.ControlPosition.TOP_LEFT
            }
          : undefined,
        streetViewControlOptions: googleSettings
          ? {
              position: googleSettings.ControlPosition.TOP_RIGHT
            }
          : undefined,
        styles: mapStyles
      }}
    >
      <Marker position={center} />
    </GoogleMap>
  ) : null
}
