import Button from '@/components/atoms/Button'
import Icon from '@/components/atoms/Icon'
import {LinkType} from '@/components/types/link'
import cn from 'classnames'
import {useState} from 'react'
import {Collapse} from 'react-collapse'
import styles from './FAQs.module.scss'

type FAQProps = {
  question: string
  answer: string
  link?: LinkType
  index: number
}

const FAQ = ({question, answer, link, index}: FAQProps) => {
  const [show, setShow] = useState(false)

  return (
    <div
      onClick={() => setShow(!show)}
      className={cn(styles.faqWrapper, show ? styles.isActive : '')}
    >
      <div
        data-aos="fade-in"
        data-aos-delay={`${(index + 1) * 100}`}
        className={styles.questionWrapper}
      >
        <div className={styles.question}>{question}</div>
        <Icon
          size={32}
          sizeViewbox={32}
          icon="plus"
          className={cn(styles.plusIcon, show ? styles.isOpen : '')}
        />
      </div>
      <div>
        <Collapse isOpened={show}>
          <div
            className={cn(styles.answerWrapper, show ? styles.isActive : '')}
          >
            <div
              className={styles.answer}
              dangerouslySetInnerHTML={{__html: answer}}
            />
            {link?.url && (
              <Button
                className={styles.button}
                type="blue"
                text={link.title}
                url={link.url}
                urlExternal={link.target}
              />
            )}
          </div>
        </Collapse>
      </div>
    </div>
  )
}

export default FAQ
