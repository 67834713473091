import Container from '@/components/atoms/Container'

import Button from '@/components/atoms/Button/Button'
import Label from '@/components/atoms/Label'
import {LinkType} from '@/components/types/link'
import {md} from '@/config'
import useMediaQuery from '@/functions/useMediaQuery'
import styles from './Locations.module.scss'
type LocationsProps = {
  block: {
    title: string
    link: LinkType
    locations: [
      {
        title: LinkType
        text: string
      }
    ]
  }
}

export default function Locations({block}: LocationsProps) {
  const {title, link, locations} = block
  const isMd = useMediaQuery(md)

  return (
    <>
      <Label title={title} link={link} />
      <Container removePaddingMobile>
        <div className={styles.servicesSubWrapper}>
          {locations?.length > 0 &&
            locations.map((location, index) => (
              <a
                key={index}
                href={location.title.url}
                className={styles.serviceSubWrapper}
              >
                <div className={styles.serviceSubContainer}>
                  <Button
                    attributes={{'data-aos': 'fade-up'}}
                    tag="div"
                    type="blue"
                    text={location?.title?.title}
                    className={styles.title}
                    icon={isMd ? null : 'arrowRightOutline'}
                  />
                  <div data-aos="fade-up" className={styles.text}>
                    {location?.text}
                  </div>
                </div>
              </a>
            ))}
        </div>
      </Container>
    </>
  )
}
