import styles from './ViewAllPosts.module.scss'

import {Post} from '@/components/types/post'

import {useRouter} from 'next/router'

import Image from 'next/image'

import Link from 'next/link'

import Container from '@/components/atoms/Container'
import {formatDate} from '@/functions/utils'

type ViewAllPostsProps = {
  block: {
    posts: {data: {posts: {nodes: Post[]}}}
  }
}

export default function ViewAllPosts({block}: ViewAllPostsProps) {
  const {posts} = block
  const router = useRouter()
  return (
    <Container>
      <div className={styles.container}>
        {posts?.data?.posts?.nodes?.map((post, index) => (
          <div key={index} className={styles.post}>
            <div>
              {post.featuredImage && (
                <div
                  onClick={() => router.push(`/news/${post.slug}`)}
                  className={styles.image}
                >
                  <Image
                    src={post.featuredImage?.node.mediaItemUrl}
                    alt={post.featuredImage?.node.altText}
                    width={post.featuredImage?.node.mediaDetails.width}
                    height={post.featuredImage?.node.mediaDetails.height}
                    layout="responsive"
                    objectFit="cover"
                    priority
                  />
                </div>
              )}
              <div className={styles.textWrapper}>
                <div className={styles.date}>{formatDate(post.date)}</div>
                <Link href={`/news/${post.slug}`}>
                  <a className={styles.title}>{post.title}</a>
                </Link>
              </div>
            </div>
          </div>
        ))}
      </div>
    </Container>
  )
}
