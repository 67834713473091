import Button from '@/components/atoms/Button'
import Container from '@/components/atoms/Container'
import {ImageType} from '@/components/types/image'
import {LinkType} from '@/components/types/link'
import Image from 'next/image'
import styles from './TextLink.module.scss'

type TextLinkProps = {
  block: {
    image?: ImageType
    link: LinkType
    title: string
    text: string
    label?: string
  }
}

export default function TextLink({block}: TextLinkProps) {
  const {image, link, title, text, label} = block
  return (
    <div className={image?.mediaItemUrl ? styles.textImageWrapper : ''}>
      <Container>
        <div className={styles.textWrapper}>
          <div className={styles.titleLabelWrapper}>
            <div data-aos="fade-up" className={styles.title}>
              {title}
            </div>
            <div data-aos="fade-up" className={styles.label}>
              {label}
            </div>
          </div>
          <div className={styles.textLinkWrapper}>
            <div
              data-aos="fade-up"
              className={styles.text}
              dangerouslySetInnerHTML={{__html: text}}
            />
            {link?.url && (
              <Button
                attributes={{'data-aos': 'fade-up'}}
                type="blue"
                className={styles.button}
                text={link.title}
                url={link.url}
                urlExternal={link.target}
              />
            )}
          </div>
        </div>
        {image?.mediaItemUrl && (
          <div className={styles.imageContainer}>
            <Image
              data-aos="fade-in"
              src={image.mediaItemUrl}
              alt={image.altText}
              width={image.mediaDetails.width}
              height={image.mediaDetails.height}
              layout="responsive"
            />
          </div>
        )}
      </Container>
    </div>
  )
}
