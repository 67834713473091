import Animation from '@/components/atoms/Animation'
import Container from '@/components/atoms/Container'
import {ImageType} from '@/components/types/image'
import Image from 'next/image'
import styles from './Image.module.scss'

type ImageProps = {
  block: {
    image: ImageType
    animation: string
  }
}

export default function ImageBlock({block}: ImageProps) {
  const {image, animation} = block

  return (
    <Container removePaddingMobile>
      <div className={styles.imageAnimationWrapper}>
        {image?.mediaItemUrl && (
          <div className={styles.imageWrapper}>
            <Image
              data-aos="fade-in"
              alt={image.altText}
              src={image.mediaItemUrl}
              width={image.mediaDetails.width}
              height={image.mediaDetails.height}
              layout="responsive"
              objectFit="cover"
            />
          </div>
        )}
        <Animation animation={animation} />
      </div>
    </Container>
  )
}
