import {changeWordpressDateFormat, dateFormat} from './dateFormat'

export const formatCourse = (course) => {
  const tempCourse = {...course}
  if (tempCourse.CourseFields.variations?.length > 0) {
    const today = new Date()
    const sortDates = tempCourse.CourseFields.variations
      ?.slice()
      .sort(function (a, b) {
        return (
          changeWordpressDateFormat(a.startDate) -
          changeWordpressDateFormat(b.startDate)
        )
      })

    const afterDates = sortDates?.filter(function (d) {
      return changeWordpressDateFormat(d.startDate) - today > 0
    })

    tempCourse.variations = []
    tempCourse.CourseFields = {
      nextCourse: null,
      ...tempCourse.CourseFields
    }
    if (afterDates?.length > 0) {
      tempCourse.CourseFields.nextCourse =
        dateFormat(changeWordpressDateFormat(afterDates[0].startDate)) +
        ' - ' +
        dateFormat(changeWordpressDateFormat(afterDates[0].endDate))
      tempCourse.CourseFields.language = afterDates[0].language

      afterDates.forEach((variation) => {
        const CourseFields = {}
        CourseFields.diploma = tempCourse.CourseFields.diploma
        CourseFields.language = variation.language
        CourseFields.nextCourse =
          dateFormat(changeWordpressDateFormat(variation.startDate)) +
          ' - ' +
          dateFormat(changeWordpressDateFormat(variation.endDate))
        tempCourse.variations.push({
          CourseFields,
          courseCategorys: course.courseCategorys,
          title: course.title
        })
      })
    }
  }

  return tempCourse
}

export const formatCourses = (courses) =>
  courses.map((course) => formatCourse(course))

export const labelMapping = {
  accreditation: 'Akkreditierung',
  applicationDeadline: 'Bewerbungsfrist',
  classSize: 'Kursgröße',
  diploma: 'Abschluss',
  duration: 'Dauer',
  format: 'Format',
  language: 'Sprache',
  nextCourse: 'Nächster Kurs',
  participationFee: 'Teilnahmegebühr',
  partnerInstitution: 'Partnereinrichtung',
  targetGroup: 'Zielgruppe'
}
