import Animation from '@/components/atoms/Animation'
import Container from '@/components/atoms/Container'
import Label from '@/components/atoms/Label'
import {LinkType} from '@/components/types/link'
import cn from 'classnames'
import FAQ from './FAQ'
import styles from './FAQs.module.scss'

type FAQsProps = {
  block: {
    title: string
    animation: string
    isHighlights: boolean
    faqs: [
      {
        question: string
        answer: string
        link?: LinkType
      }
    ]
  }
}

export default function FAQs({block}: FAQsProps) {
  const {faqs, title, isHighlights, animation} = block
  return (
    <div>
      {!isHighlights && (
        <div data-aos="fade-in">
          <Label title={title} />
        </div>
      )}
      <Container>
        <div
          className={cn(
            styles.wrapper,
            isHighlights ? styles.isHighlights : ''
          )}
        >
          <div
            className={cn(
              styles.faqsWrapper,
              isHighlights ? styles.isHighlights : ''
            )}
          >
            {isHighlights && (
              <div className={styles.labelWrapper}>
                <Label hasParent title={title} isSmall removeBorders />
              </div>
            )}
            {faqs.map((faq, index) => (
              <FAQ {...faq} index={index} key={index} />
            ))}
          </div>
          <div className={styles.animationWrapper}>
            <Animation animation={animation} />
          </div>
        </div>
      </Container>
    </div>
  )
}
