import styles from './RelatedPosts.module.scss'

import Container from '@/components/atoms/Container'

import Icon from '@/components/atoms/Icon'

import {Post} from '@/components/types/post'

import {useRouter} from 'next/router'

import Image from 'next/image'

import Link from 'next/link'

import {MouseEventHandler} from 'react'

import cn from 'classnames'

import Label from '@/components/atoms/Label'
import {formatDate} from '@/functions/utils'

import Slider from 'react-slick'

type RelatedPostsProps = {
  block: {
    title: string
    posts: {data: {posts: {nodes: Post[]}}}
  }
}

type ArrowProps = {
  className?: string
  onClick?: MouseEventHandler<any>
}

function Arrow(props: ArrowProps) {
  const {className, onClick} = props
  return (
    <div onClick={onClick} className={className}>
      <div className={styles.circle}>
        <Icon icon="sliderArrow" size={40} sizeViewbox={40} />
      </div>
    </div>
  )
}

export default function RelatedPosts({block}: RelatedPostsProps) {
  const {title} = block
  const router = useRouter()
  const posts = block?.posts?.data?.posts?.nodes

  const sliderSettings = {
    dots: false,
    infinite: false,
    speed: 500,
    arrows: true,
    draggable: true,
    slidesToShow: 3,
    slidesToScroll: 1,
    nextArrow: <Arrow />,
    prevArrow: <Arrow />,
    variableWidth: true,
    responsive: [
      {
        breakpoint: 1599,
        settings: {
          arrows: true,
          draggable: true,
          slidesToShow: 3
        }
      },
      {
        breakpoint: 1132,
        settings: {
          arrows: true,
          draggable: true,
          slidesToShow: 2
        }
      },
      {
        breakpoint: 767,
        settings: {
          arrows: true,
          draggable: true,
          slidesToShow: 1
        }
      }
    ]
  }

  return posts?.length > 0 ? (
    <div
      className={cn(
        styles.relatedPostsWrapper,
        posts?.length <= 3 ? styles.noSliderButtons : ''
      )}
    >
      {title && (
        <div data-aos="fade-in">
          <Label title={title} />
        </div>
      )}
      <Container>
        <div className={styles.sliderWrapper}>
          <Slider {...sliderSettings}>
            {posts.map((post, index) => (
              <div key={index} className={styles.slide}>
                <div>
                  {post.featuredImage && (
                    <div
                      onClick={() => router.push(`/news/${post.slug}`)}
                      className={styles.slideImage}
                    >
                      <Image
                        src={post.featuredImage?.node.mediaItemUrl}
                        alt={post.featuredImage?.node.altText}
                        width={post.featuredImage?.node.mediaDetails.width}
                        height={post.featuredImage?.node.mediaDetails.height}
                        layout="responsive"
                        objectFit="cover"
                        priority
                      />
                    </div>
                  )}
                  <div className={styles.textWrapper}>
                    <div className={styles.date}>{formatDate(post.date)}</div>
                    <Link href={`/news/${post.slug}`}>
                      <a className={styles.slideTitle}>{post.title}</a>
                    </Link>
                  </div>
                </div>
              </div>
            ))}
          </Slider>
        </div>
      </Container>
    </div>
  ) : null
}
