import styles from './Text.module.scss'

import Container from '@/components/atoms/Container'

type TextProps = {
  block: {
    text: string
  }
}

export default function Text({block}: TextProps) {
  const {text} = block
  return (
    <Container>
      <div data-aos="fade-up" className={styles.text}>
        {text}
      </div>
    </Container>
  )
}
