import PropTypes from 'prop-types'
import styles from './InputError.module.scss'

/**
 * Render the InputError component.
 *
 * @param  {object}        props      The component attributes as props.
 * @param  {string|number} props.name Input id.
 * @return {Element}                  The InputError component.
 */
export default function InputError({name}) {
  return <span className={styles.inputError}>{name}</span>
}

InputError.propTypes = {
  name: PropTypes.oneOfType([PropTypes.number, PropTypes.string])
}
