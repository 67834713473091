import styles from './Video.module.scss'

import Container from '@/components/atoms/Container'

import VideoComponent from '@/components/atoms/Video'

type VideoProps = {
  block: {
    mediaItemUrl: string
  }
}

export default function Video({block}: VideoProps) {
  return (
    <Container>
      <div className={styles.videoWrapper}>
        <VideoComponent video={block} />
      </div>
    </Container>
  )
}
