import * as Input from '@/components/atoms/Inputs'
import {useWordPressContext} from '@/components/common/WordPressProvider'
import {formatCourse} from '@/functions/formatCourses'
import {
  getGfFieldId,
  getGfHiddenClassName
} from '@/functions/wordpress/gravityForms'
import cn from 'classnames'
import PropTypes from 'prop-types'

/**
 * Render GravityForms Select field component.
 *
 * @param  {object}        props               GravityForm Select field as props.
 * @param  {string}        props.className     GravityForm field wrapper class.
 * @param  {string}        props.description   GravityForm field description.
 * @param  {string|number} props.id            GravityForm field id.
 * @param  {boolean}       props.isRequired    GravityForm field is required.
 * @param  {string}        props.label         GravityForm field label.
 * @param  {Array}         props.selectChoices GravityForm field selection options.
 * @param  {boolean}       props.visibility    GravityForm field visibility.
 * @return {Element}                           The Select component.
 */
export default function Select({
  className,
  description,
  id,
  isRequired,
  label,
  selectChoices,
  visibility,
  placeholder,
  errorMessage,
  setFieldValue,
  adminLabel,
  cssClass
}) {
  const fieldId = getGfFieldId(id)
  const isHiddenClass = getGfHiddenClassName(visibility)
  const {post} = useWordPressContext()
  let customOptions = []
  if (adminLabel === 'Course-Dates') {
    const formattedPost = formatCourse(post)
    customOptions = formattedPost.variations.map((variation) => {
      return {
        text: variation.CourseFields.nextCourse,
        value: variation.CourseFields.nextCourse,
        __typename: 'SelectFieldChoice',
        isSelected: false
      }
    })
  }
  return (
    <Input.Select
      className={cn(className, cssClass, isHiddenClass) || null}
      description={description}
      id={fieldId}
      isRequired={isRequired}
      label={label}
      options={customOptions.length > 0 ? customOptions : selectChoices}
      placeholder={placeholder}
      errorMessage={errorMessage}
      setFieldValue={setFieldValue}
    />
  )
}

Select.propTypes = {
  className: PropTypes.string,
  description: PropTypes.string,
  id: PropTypes.number.isRequired,
  isRequired: PropTypes.bool,
  label: PropTypes.string,
  selectChoices: PropTypes.arrayOf(PropTypes.object),
  visibility: PropTypes.string
}
