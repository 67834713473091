import PropTypes from 'prop-types'

import BlockButton from '@/components/blocks/core/BlockButton'
import BlockButtons from '@/components/blocks/core/BlockButtons'
import BlockCode from '@/components/blocks/core/BlockCode'
import BlockColumns from '@/components/blocks/core/BlockColumns'
import BlockCover from '@/components/blocks/core/BlockCover'
import BlockEmbed from '@/components/blocks/core/BlockEmbed'
import BlockGravityForm from '@/components/blocks/core/BlockGravityForm'
import BlockHeadings from '@/components/blocks/core/BlockHeadings'
import BlockImage from '@/components/blocks/core/BlockImage'
import BlockImageGallery from '@/components/blocks/core/BlockImageGallery'
import BlockList from '@/components/blocks/core/BlockList'
import BlockMediaText from '@/components/blocks/core/BlockMediaText'
import BlockParagraph from '@/components/blocks/core/BlockParagraph'
import BlockPullQuote from '@/components/blocks/core/BlockPullQuote'
import BlockQuote from '@/components/blocks/core/BlockQuote'
import BlockSeparator from '@/components/blocks/core/BlockSeparator'
import BlockSpacer from '@/components/blocks/core/BlockSpacer'
import BlockTable from '@/components/blocks/core/BlockTable'
import BlockVideo from '@/components/blocks/core/BlockVideo'
import Contacts from '@/components/blocks/custom/Contacts'
import Facts from '@/components/blocks/custom/Facts'
import Faqs from '@/components/blocks/custom/FAQs'
import ImageBlock from '@/components/blocks/custom/Image'
import ImageLink from '@/components/blocks/custom/ImageLink'
import ImageText from '@/components/blocks/custom/ImageText'
import Intro from '@/components/blocks/custom/Intro'
import Label from '@/components/blocks/custom/Label'
import Locations from '@/components/blocks/custom/Locations'
import LocationsLinks from '@/components/blocks/custom/LocationsLinks'
import Logos from '@/components/blocks/custom/Logos'
import RelatedPosts from '@/components/blocks/custom/RelatedPosts'
import Services from '@/components/blocks/custom/Services'
import Teaser from '@/components/blocks/custom/Teaser'
import Text from '@/components/blocks/custom/Text'
import TextLink from '@/components/blocks/custom/TextLink'
import ViewAllPosts from '@/components/blocks/custom/ViewAllPosts'

export default function displayBlock(block, index) {
  const {attributes, name, innerBlocks} = block

  switch (name) {
    case 'core/button':
      return <BlockButton {...attributes} key={index} />
    case 'core/buttons':
      return (
        <BlockButtons {...attributes} innerBlocks={innerBlocks} key={index} />
      )
    case 'core/code':
    case 'core/preformatted':
      return <BlockCode {...attributes} key={index} />
    case 'core/columns':
      return (
        <BlockColumns {...attributes} innerBlocks={innerBlocks} key={index} />
      )
    case 'core/cover':
      return (
        <BlockCover {...attributes} innerBlocks={innerBlocks} key={index} />
      )
    case 'core/embed':
      return <BlockEmbed {...attributes} key={index} />
    case 'core/gallery':
      return <BlockImageGallery {...attributes} key={index} />
    case 'core/heading':
      return <BlockHeadings {...attributes} key={index} />
    case 'core/image':
      return <BlockImage {...attributes} key={index} />
    case 'core/list':
      return <BlockList {...attributes} key={index} />
    case 'core/media-text':
      return (
        <BlockMediaText {...attributes} innerBlocks={innerBlocks} key={index} />
      )
    case 'core/paragraph':
      return <BlockParagraph {...attributes} key={index} />
    case 'core/pullquote':
      return <BlockPullQuote {...attributes} key={index} />
    case 'core/quote':
      return <BlockQuote {...attributes} key={index} />
    case 'core/separator':
      return <BlockSeparator {...attributes} key={index} />
    case 'core/spacer':
      return <BlockSpacer {...attributes} key={index} />
    case 'core/table':
      return <BlockTable {...attributes} key={index} />
    case 'core/video':
      return <BlockVideo {...attributes} key={index} />
    case 'gravityforms/form':
      return <BlockGravityForm {...attributes} key={index} />
    case 'acf/intro':
      return <Intro {...attributes} key={index} />
    case 'acf/services':
      return <Services {...attributes} key={index} />
    case 'acf/facts':
      return <Facts {...attributes} key={index} />
    case 'acf/faqs':
      return <Faqs {...attributes} key={index} />
    case 'acf/image-text':
      return <ImageText {...attributes} key={index} />
    case 'acf/text-link':
      return <TextLink {...attributes} key={index} />
    case 'acf/locations':
      return <Locations {...attributes} key={index} />
    case 'acf/teaser':
      return <Teaser {...attributes} key={index} />
    case 'acf/image-link':
      return <ImageLink {...attributes} key={index} />
    case 'acf/text':
      return <Text {...attributes} key={index} />
    case 'acf/logos':
      return <Logos {...attributes} key={index} />
    case 'acf/contacts':
      return <Contacts {...attributes} key={index} />
    case 'acf/locations-links':
      return <LocationsLinks {...attributes} key={index} />
    case 'acf/label':
      return <Label {...attributes} key={index} />
    case 'acf/image':
      return <ImageBlock {...attributes} key={index} />
    case 'acf/all-posts':
      return <RelatedPosts {...attributes} key={index} />
    case 'acf/view-all-posts':
      return <ViewAllPosts {...attributes} key={index} />
    default:
      return <pre key={index}>{JSON.stringify(block, null, 2)}</pre>
  }
}

displayBlock.propTypes = {
  block: PropTypes.object.isRequired,
  index: PropTypes.number.isRequired
}
