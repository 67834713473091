import InputError from '@/components/atoms/Inputs/InputError'
import stylesText from '@/components/atoms/Inputs/Text/Text.module.scss'
import styles from './File.module.scss'

import Icon from '@/components/atoms/Icon'
import {getGfFieldId} from '@/functions/wordpress/gravityForms'
import cn from 'classnames'
import {Field} from 'formik'
import PropTypes from 'prop-types'
import {useEffect, useState} from 'react'

/**
 * Render GravityForms File field component.
 *
 * @param  {object}        props               GravityForm Select field as props.
 * @param  {string}        props.className     GravityForm field wrapper class.
 * @param  {string}        props.description   GravityForm field description.
 * @param  {string|number} props.id            GravityForm field id.
 * @param  {boolean}       props.isRequired    GravityForm field is required.
 * @param  {string}        props.label         GravityForm field label.
 * @param  {boolean}       props.visibility    GravityForm visibility option.
 * @param  {Function}      props.setFieldValue Formik function to set state.
 * @return {Element}                           The File component.
 */
export default function File({
  className,
  description,
  id,
  isRequired,
  label,
  visibility,
  setFieldValue,
  conditionalLogic,
  values
}) {
  const fieldId = getGfFieldId(id)
  const thisClassName = cn(className)
  const [fileName, setFileName] = useState('')
  const [showError, setShowError] = useState('')
  const [removeHidden, setRemoveHidden] = useState(false)

  useEffect(() => {
    if (conditionalLogic && conditionalLogic.rules.length > 0) {
      const fieldValue =
        values[`field-${conditionalLogic.rules[0].fieldId}_filedata`]
      setRemoveHidden(fieldValue !== conditionalLogic.rules[0].value)
    }
  }, [conditionalLogic, values])

  return (
    <div
      className={cn(
        stylesText.text,
        showError ? stylesText.isError : '',
        thisClassName,
        visibility === 'HIDDEN' && !removeHidden ? styles.isHidden : ''
      )}
    >
      <div className={styles.container}>
        {label && (
          <label htmlFor={id} required={isRequired}>
            {label}
          </label>
        )}
        <div className={styles.fileWrapper}>
          <div
            className={cn(
              styles.filePlaceholder,
              showError ? styles.hasError : ''
            )}
          >
            {fileName.length === 0 && (
              <Icon
                icon={'plusFile'}
                size={'24'}
                sizeViewbox={'24'}
                title={'plus file'}
              />
            )}
            {fileName.length > 0 ? fileName : 'Datei'}
          </div>
          <Field
            aria-required={isRequired}
            id={fieldId}
            name={fieldId}
            required={isRequired}
            className={styles.file}
            type="file"
            accept="application/pdf"
            onInvalid={() => setShowError(true)}
            onChange={(e) => {
              setFileName(e.currentTarget.files[0].name)
              setShowError(false)
              // Save to _filedata here so we don't corrupt state.
              setFieldValue(`${fieldId}_filedata`, e.currentTarget.files[0])
            }}
          />
        </div>
      </div>
      {description && <p>{description}</p>}
      {showError ? (
        <InputError name={'Unterstütztes Dateiformat: PDF'} />
      ) : (
        <div className={styles.showLabel}>Unterstütztes Dateiformat: PDF</div>
      )}
    </div>
  )
}

File.propTypes = {
  className: PropTypes.string,
  description: PropTypes.string,
  id: PropTypes.number.isRequired,
  isRequired: PropTypes.bool,
  label: PropTypes.string,
  selectChoices: PropTypes.arrayOf(PropTypes.object),
  visibility: PropTypes.string,
  setFieldValue: PropTypes.func
}
