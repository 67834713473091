import Container from '@/components/atoms/Container'
import Icon from '@/components/atoms/Icon/Icon'
import Label from '@/components/atoms/Label'
import {md} from '@/config'
import useMediaQuery from '@/functions/useMediaQuery'
import cn from 'classnames'
import {useState} from 'react'
import FAQ from '../FAQs/FAQ'
import styles from './Contacts.module.scss'

type ContactsProps = {
  block: {
    title: string
    cards: [
      {
        title: string
        text: string
      }
    ]
  }
}

export default function Contacts({block}: ContactsProps) {
  const {title, cards} = block

  const isMd = useMediaQuery(md)

  const [active, setActive] = useState(0)
  const [fadeOut, setFadeOut] = useState(false)

  const fadeOutFunction = (index: number) => {
    setFadeOut(true)
    setTimeout(() => {
      setActive(index)
      setFadeOut(false)
    }, 500)
  }

  return (
    <Container removePaddingMobile>
      <div className={styles.contacts}>
        {isMd && cards?.length > 0 && cards[active] && (
          <div
            className={cn(
              styles.text,
              fadeOut ? styles.fadeOut : styles.fadeIn
            )}
            dangerouslySetInnerHTML={{__html: cards[active].text}}
          />
        )}
        <div className={styles.tabs}>
          <div data-aos="fade-in" className={styles.labelWrapper}>
            <Label hasParent title={title} isSmall removeBorders />
          </div>
          <div className={styles.listWrapper}>
            {cards?.length > 0 &&
              cards.map((card, index) =>
                isMd ? (
                  <div
                    key={index}
                    onClick={() => fadeOutFunction(index)}
                    className={cn(
                      styles.title,
                      index === active ? styles.isActive : ''
                    )}
                  >
                    {card.title}
                    <Icon
                      icon="arrowRightContacts"
                      size={32}
                      sizeViewbox={32}
                    />
                  </div>
                ) : (
                  <FAQ
                    index={index}
                    key={index}
                    question={card.title}
                    answer={card.text}
                  />
                )
              )}
          </div>
        </div>
      </div>
    </Container>
  )
}
