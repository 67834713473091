import Button from '../../../atoms/Button/Button'

import styles from './Intro.module.scss'

import Container from '@/components/atoms/Container'

import Animation from '@/components/atoms/Animation'
import {LinkType} from '@/components/types/link'
type IntroProps = {
  block: {
    text: string
    animation: string
    link: LinkType
  }
}

export default function Intro({block}: IntroProps) {
  const {text, link, animation} = block

  return (
    <Container>
      <div className={styles.intro}>
        <div className={styles.textWrapper}>
          <div className={styles.textContainer}>
            <h1
              data-aos="fade-up"
              className={styles.text}
              dangerouslySetInnerHTML={{__html: text}}
            />
            {link && (
              <Button
                attributes={{'data-aos': 'fade-up'}}
                type="primary"
                url={link.url}
                text={link.title}
                urlExternal={link.target}
              />
            )}
          </div>
        </div>
        <Animation animation={animation} />
      </div>
    </Container>
  )
}
