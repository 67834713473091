import InputError from '@/components/atoms/Inputs/InputError'
import cn from 'classnames'
import {Field} from 'formik'
import PropTypes from 'prop-types'
import {useState} from 'react'
import styles from './Text.module.scss'

/**
 * Render the Text component.
 *
 * @author WebDevStudios
 * @param  {object}  props             The component attributes as props.
 * @param  {string}  props.className   Text wrapper className.
 * @param  {string}  props.description Text description.
 * @param  {string}  props.id          Text input id.
 * @param  {string}  props.label       Text input label.
 * @param  {boolean} props.isRequired  If input is required.
 * @param  {string}  props.type        Text input type.
 * @return {Element}                   The Text component.
 */
export default function Text({
  className,
  description,
  id,
  isRequired,
  label,
  type,
  placeholder,
  errorMessage,
  setFieldValue
}) {
  const [value, setValue] = useState('')
  const [showError, setShowError] = useState(false)

  return (
    <div
      className={cn(
        styles.text,
        className,
        type === 'HIDDEN' ? styles.isHidden : '',
        showError ? styles.isError : ''
      )}
    >
      <div className={styles.textContainer}>
        {label && type !== 'HIDDEN' && (
          <label htmlFor={id} required={isRequired}>
            {label}
          </label>
        )}
        <Field
          aria-required={isRequired}
          id={id}
          name={id}
          required={isRequired}
          type={type}
          placeholder={placeholder}
          onInvalid={() => setShowError(true)}
          onChange={(e) => {
            setValue(e.target.value)
            setFieldValue(`${id}_filedata`, e.target.value)
            e.target.value === '' ? setShowError(true) : setShowError(false)
            type === 'EMAIL' &&
            !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(e.target.value)
              ? setShowError(true)
              : setShowError(false)
          }}
          value={value}
        />
      </div>
      {description && <p>{description}</p>}
      {showError && <InputError name={errorMessage} />}
    </div>
  )
}

Text.propTypes = {
  className: PropTypes.string,
  description: PropTypes.string,
  id: PropTypes.string.isRequired,
  isRequired: PropTypes.bool,
  label: PropTypes.string,
  type: PropTypes.string.isRequired
}

Text.defaultProps = {
  isRequired: false
}
