import InputError from '@/components/atoms/Inputs/InputError'
import cn from 'classnames'
import {Field} from 'formik'
import PropTypes from 'prop-types'
import {useState} from 'react'
import styles from './Select.module.scss'

/**
 * Render the Select component.
 *
 * @author WebDevStudios
 * @param  {object}  props             The component attributes as props.
 * @param  {string}  props.className   Select wrapper className.
 * @param  {string}  props.description Select description.
 * @param  {string}  props.label       Select input label.
 * @param  {string}  props.id          Select input id.
 * @param  {boolean} props.isRequired  If input is required.
 * @param  {Array}   props.options     Array of input options objects.
 * @return {Element}                   The Select component.
 */
export default function Select({
  className,
  description,
  id,
  isRequired,
  label,
  options,
  placeholder,
  errorMessage,
  setFieldValue
}) {
  const [showError, setShowError] = useState(false)

  return (
    <div
      className={cn(styles.select, className, showError ? styles.isError : '')}
    >
      <div className={styles.container}>
        {label && <label required={isRequired}>{label}</label>}
        <div className={styles.selectWrapper}>
          <Field
            onInvalid={() => setShowError(true)}
            as="select"
            id={id}
            name={id}
            required={isRequired}
            onChange={(e) => {
              e.target.value === '' ? setShowError(true) : setShowError(false)
              setFieldValue(`${id}_filedata`, e.target.value)
            }}
          >
            <option value="" disabled={isRequired} selected>
              {placeholder}
            </option>

            {!!options?.length > 0 &&
              options.map((option, key) => {
                const {text, value} = option

                return (
                  <option key={key} value={value}>
                    {text}
                  </option>
                )
              })}
            {description && <p>{description}</p>}
          </Field>
        </div>
      </div>
      {showError && <InputError name={errorMessage} />}
    </div>
  )
}

Select.propTypes = {
  className: PropTypes.string,
  description: PropTypes.string,
  id: PropTypes.string.isRequired,
  isRequired: PropTypes.bool,
  label: PropTypes.string,
  options: PropTypes.arrayOf(PropTypes.object)
}

Select.defaultProps = {
  isRequired: false
}
