import cn from 'classnames'
import {Field} from 'formik'
import PropTypes from 'prop-types'
import {useState} from 'react'
import styles from './Checkbox.module.scss'
/**
 * Render Checkbox component.
 *
 * @param  {object}        props           The component attributes as props.
 * @param  {string}        props.className Input className.
 * @param  {string|number} props.id        Input id.
 * @param  {string}        props.label     Input label.
 * @param  {string}        props.name      Input name.
 * @param  {string}        props.value     Input value.
 * @return {Element}                       The Checkbox component.
 */
export default function Checkbox({
  className,
  id,
  label,
  name,
  isRequired,
  setFieldValue
}) {
  const [isChecked, setIsChecked] = useState(false)
  const [error, setError] = useState(false)

  return (
    <div
      className={cn(className, styles.checkbox, error ? styles.isError : '')}
    >
      {label && (
        <>
          <Field
            id={id}
            name={name}
            type="checkbox"
            onChange={() => {
              setIsChecked(!isChecked)
              setFieldValue(`${id}_filedata`, `${isChecked}`)
              isChecked ? setError(false) : null
            }}
            checked={isChecked}
            required={isRequired}
            onInvalid={() => setError(true)}
          />
          <div
            className={styles.label}
            dangerouslySetInnerHTML={{__html: label}}
          />
        </>
      )}
    </div>
  )
}

Checkbox.propTypes = {
  className: PropTypes.string,
  id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  label: PropTypes.string,
  name: PropTypes.string,
  value: PropTypes.string
}
